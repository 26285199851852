import { gql } from "@apollo/client";
import {
  AirlockBlockConfig,
  AppSessionCellId,
  BlockCellId,
  CELL_TYPE_BY_CHAIN_TEMPLATE_TYPE,
  CREATE_CELL,
  CellExecutionState,
  CellId,
  CellType,
  ChartTypeSelectorOption,
  DELETE_CELL,
  DISCARD_CHAIN,
  DataConnectionId,
  DataSourceTableId,
  INTERRUPT_APP_SESSION,
  MagicCellChainTemplateType,
  MagicCellEditWithInput,
  MagicCellRunResult,
  MagicCellType,
  MagicEventFailureReason,
  MagicEventId,
  MagicEventSource,
  MagicEventStatus,
  MagicKeyword,
  MagicNonStreamingCellType,
  MagicRequestMode,
  MagicTypeAheadProvider,
  MentionedDataframeName,
  OrgRole,
  ProjectLanguage,
  RichTextDocument,
  SmartEditType,
  StaticCellId,
  StoryElementId,
  UserId,
  createBlockCellPayload,
  createStoryElementPayload,
  notEmpty,
  typedObjectValues,
  uuid,
} from "@hex/common";
import { last, orderBy } from "lodash";
import { useCallback, useMemo } from "react";
import { shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { Literal, Static, Union } from "runtypes";

import { useAppSessionCellsSelector } from "../appsession-multiplayer/state-hooks/appSessionCellsStateHooks.js";
import { useScopeSelector } from "../appsession-multiplayer/state-hooks/scopeStateHooks.js";
import { useFeatureGates } from "../components/feature-gate/FeatureGateContext";
import { getSubmitArgsFromRichText } from "../components/magic/MagicInput.js";
import { useCellData } from "../components/output/useCellData.js";
import { getEjectBlockCellOps } from "../hex-version-multiplayer/handlers/cell/block/ejectBlockCellHelper.js";
import { getAcceptMagicEventOps } from "../hex-version-multiplayer/handlers/magic-event/utils.js";
import { MagicEventFragment } from "../hex-version-multiplayer/HexVersionMPModel.generated.js";
import {
  useAirlockCellIdsGetter,
  useBlockChildCellSelector,
} from "../hex-version-multiplayer/state-hooks/blockCellHooks.js";
import { useCellContentsGetter } from "../hex-version-multiplayer/state-hooks/cellContentsStateHooks.js";
import { useCellsContentsSelector } from "../hex-version-multiplayer/state-hooks/cellsContentsStateHooks.js";
import { useCellsGetter } from "../hex-version-multiplayer/state-hooks/cellsStateHooks.js";
import { useCellGetter } from "../hex-version-multiplayer/state-hooks/cellStateHooks.js";
import {
  useMagicEventsGetter,
  useMagicEventsSelector,
} from "../hex-version-multiplayer/state-hooks/magicEventStateHooks.js";
import { useCreateSmartEdit } from "../magic/useCreateSmartEdit.js";
import { useDispatch, useSelector, useStore } from "../redux/hooks.js";
import { AppSessionCellMP } from "../redux/slices/appSessionMPSlice.js";
import {
  CellContentsMP,
  CellMP,
  STILL_THINKING_THRESHOLD,
  hexVersionMPActions,
  hexVersionMPSelectors,
} from "../redux/slices/hexVersionMPSlice.js";
import {
  closeDraftAirlock,
  openDraftAirlock,
  setDraftAirlockOrder,
  setDraftAirlockPromptV2,
  setLastUsedDataConnection,
} from "../redux/slices/logicViewSlice.js";
import { useAppSessionAOContext } from "../util/appSessionAOContext.js";
import { getSortedCells } from "../util/cellLayoutHelpers.js";
import { isMagicTypeaheadEnabled } from "../util/data";
import { useHexVersionAOContext } from "../util/hexVersionAOContext.js";
import { getTraceback } from "../util/outputUtils.js";
import { useProjectContext } from "../util/projectContext.js";
import { useHexFlag } from "../util/useHexFlags";

import { useAppSessionCellIdMap } from "./appSession/useAppSessionCellIdMap.js";
import { useGetSelectedCellIds, useSelectCell } from "./cell/useSelectCell.js";
import {
  useCancelMagicMutation,
  useEditCellSyncMutation,
  useGenerateChainMutation,
  useGetUserForMagicQuery,
  useResumeChainMutation,
} from "./magicHooks.generated.js";
import { useCurrentUser } from "./me/useCurrentUser.js";
import { useUserForSettings } from "./me/useUserForSettings.js";
import { useProjectCellCount } from "./useProjectCellCount.js";

gql`
  query GetUserForMagic {
    me {
      id
      hexMagic
      magicAutoRunOnKeep
      overMagicUsageLimit
      org {
        id
        allowMagic
        magicSettings
      }
    }
  }
`;

export const useMagicCellChainsEnabled = (): boolean => {
  const { language } = useProjectContext();
  return language === ProjectLanguage.PYTHON;
};

export function useUserForMagic(): {
  loading: boolean;
  magicAllowedForOrg: boolean;
  overMagicUsageLimit: boolean;
  magicEnabled: boolean;
  magicFeatureFlag: boolean;
  magicAutoRunOnKeep: boolean;
  onboardedToMagic: boolean;
  onboardedToMagicWithTypeahead: boolean;
  userPreviouslyAcceptedTos: boolean;
} {
  const { data, loading } = useGetUserForMagicQuery();
  const { allowedMonthlyMagicRequests } = useFeatureGates();
  const { userSettings } = useUserForSettings();

  const magicUngated =
    allowedMonthlyMagicRequests == null || allowedMonthlyMagicRequests > 0;

  const magicFeatureFlag = useHexFlag("magic-enabled");
  const typeaheadFeatureFlag =
    useHexFlag("magic-typeahead-provider-code") !== MagicTypeAheadProvider.OFF;
  const magicEnabled = (magicUngated && data?.me?.org.allowMagic) ?? false;

  const userOnboardedToMagicWithTypeaheadTos =
    userSettings?.magicTypeahead.magicTosAcceptedJuly2024 ?? false;
  const orgOnboardedToMagicWithTypeaheadTos =
    data?.me?.org.magicSettings?.acceptedMagicTosJuly2024;

  // If the org has onboarded to magic with typeahead, we don't need to check the user's settings
  const onboardedToMagicWithTypeaheadTos =
    orgOnboardedToMagicWithTypeaheadTos ?? userOnboardedToMagicWithTypeaheadTos;
  let onboardedToMagic = data?.me?.hexMagic ?? false;

  // if both the feature flag and the API key is correctly enabled, use this to
  // determine if we should use th enew TOS rather
  if (typeaheadFeatureFlag && isMagicTypeaheadEnabled) {
    onboardedToMagic = onboardedToMagicWithTypeaheadTos;
  }
  return {
    magicEnabled: magicEnabled && magicFeatureFlag,
    magicFeatureFlag,
    loading,
    magicAllowedForOrg: data?.me?.org.allowMagic ?? false,
    overMagicUsageLimit: data?.me?.overMagicUsageLimit ?? false,
    magicAutoRunOnKeep: data?.me?.magicAutoRunOnKeep ?? false,
    onboardedToMagic,
    onboardedToMagicWithTypeahead: onboardedToMagicWithTypeaheadTos,
    userPreviouslyAcceptedTos: data?.me?.hexMagic ?? false,
  };
}

export const useCanAccessDataManager = () => {
  const { orgDataConnections: dataManagerAllowed } = useFeatureGates();
  const user = useCurrentUser();

  return dataManagerAllowed && user?.orgRole === OrgRole.ADMIN;
};

export const useMagicEventSelector = (
  magicEventId?: MagicEventId | null,
): MagicEventFragment | null => {
  const selector = useMemo(
    () =>
      createSelector(
        (
          magicEventState: Record<MagicEventId, MagicEventFragment | undefined>,
        ) => magicEventState,
        (magicEventState) =>
          magicEventId == null ? null : magicEventState[magicEventId] ?? null,
      ),
    [magicEventId],
  );
  return useMagicEventsSelector({
    selector,
    equalityFn: shallowEqual,
  });
};

export const usePendingMagicEvents = () => {
  const pending = useMagicEventsSelector({
    equalityFn: shallowEqual,
    selector: (magicEventState) => {
      return Object.values(magicEventState).some(
        (event) =>
          event &&
          event?.status !== MagicEventStatus.REVIEWED &&
          event?.status !== MagicEventStatus.CANCELLED,
      );
    },
  });
  return pending;
};

export const useLatestChainEventsSelector = (
  parentEventId: MagicEventId | null,
  chainCellIds: CellId[],
): MagicEventFragment[] | null => {
  const selector = useMemo(
    () =>
      createSelector(
        (
          magicEventState: Record<MagicEventId, MagicEventFragment | undefined>,
        ) => magicEventState,
        (magicEventState) => {
          return orderBy(
            typedObjectValues(magicEventState)
              .filter(
                (event) =>
                  event?.parentEventId === parentEventId &&
                  event?.cellId != null &&
                  chainCellIds.includes(event.cellId),
              )
              .filter(notEmpty),
            // We order first by the index of the cell in the chain, then by the createdAt date
            [(evt) => chainCellIds.indexOf(evt.cellId!), "createdAt"],
            ["asc", "desc"],
            //  Then we can filter out duplicates to get the latest event for each cell
          ).filter(
            (item, pos, ary) => !pos || item.cellId !== ary[pos - 1].cellId,
          );
        },
      ),
    [chainCellIds, parentEventId],
  );
  return useMagicEventsSelector({
    selector,
    equalityFn: shallowEqual,
  });
};

export interface LatestAirlockState {
  airlockRequestMode: MagicRequestMode | undefined;
  allRunStates: (CellExecutionState | undefined)[];
  chainTemplateType: MagicCellChainTemplateType | undefined;
  hasError: boolean;
  isPaused: boolean;
  isLoading: boolean;
  chainFailureReason: MagicEventFailureReason | undefined;
  parentEventId?: MagicEventId;
  lastPrompt: string | undefined;
  latestMagicEvents: (MagicEventFragment | undefined)[]; // last event for every cell
  loadingIndex: number;
  isFinished: boolean;
  isErrored: boolean;
  cellRunTimeout: boolean;
  airlockOwner: UserId | null;
  cells: CellMP[];
  childCellRunStates: CellExecutionState[];
  noDataFromLatestSqlCell: boolean;
  dataConnectionId?: DataConnectionId;
  parent: MagicEventFragment | null;
  expectedCellCount: number | null;
  pausedOnUnsupportedRequestMode: boolean;
  pausedOnDeflection: boolean;
}

const selectAirlockExists = createSelector(
  (cellContents: Record<CellId, CellContentsMP | undefined>) => cellContents,
  (cellContents) =>
    Object.values(cellContents ?? {}).some(
      (cell) =>
        cell?.deletedDate == null &&
        cell?.__typename === "BlockCell" &&
        AirlockBlockConfig.guard(cell.blockConfig),
    ),
);

export const useIsAirlockOpen = (): boolean => {
  const airlockExists = useCellsContentsSelector({
    selector: selectAirlockExists,
  });
  return airlockExists;
};

export const useDraftAirlockGetter = (): (() => boolean) => {
  const store = useStore();

  return useCallback(() => {
    return store.getState().logicView.draftAirlockState.cursor?.isOpen ?? false;
  }, [store]);
};

export const useLatestAirlockState = ({
  blockCellId,
  parentEventId,
}: {
  parentEventId: MagicEventId | null;
  blockCellId: BlockCellId;
}): LatestAirlockState => {
  const parent = useMagicEventSelector(parentEventId);
  const airlockOwner = parent?.userId ?? null;
  const chainTemplateType = parent?.chainTemplateType ?? undefined;
  const lastPrompt = parent?.userProvidedPrompt ?? undefined;
  // when we have a parent event id but not a parent that means we're still
  // waiting for the magic even to be created
  const isLoading =
    (parentEventId != null && parent == null) ||
    parent?.status === MagicEventStatus.LOADING;
  const isErrored = parent?.status === MagicEventStatus.ERROR;
  const scopeMap = useScopeSelector({
    selector: (x) => x,
  });

  const _childCells =
    useBlockChildCellSelector({
      blockCellId,
      selector: (cellsState) => cellsState,
    }) ?? [];

  const childCells = _childCells
    .filter(notEmpty)
    .filter((cellMp) => cellMp.deletedDate == null);
  const sortedCells = getSortedCells(childCells);
  const childEvents = useLatestChainEventsSelector(
    parentEventId,
    sortedCells.map((c) => c.id),
  );
  const appSessionCellMap = useAppSessionCellIdMap();

  const appSessionCellIds = sortedCells.map(
    (cell) => appSessionCellMap[cell.id],
  );

  const airlockSessionCellSelector = useMemo(
    () =>
      createSelector(
        (
          appSessionCellsState: Record<
            AppSessionCellId,
            AppSessionCellMP | undefined
          >,
        ) => appSessionCellsState,
        (appSessionCellsState) =>
          appSessionCellIds.map((id) => appSessionCellsState[id]?.state),
      ),
    [appSessionCellIds],
  );

  const { hexVersionId } = useProjectContext();
  const latestMagicEvents = useSelector((state) => {
    const entities = state.hexVersionMP[hexVersionId]?.magicEvents.entities;
    if (!entities) return [];
    return sortedCells.map(
      (c) => entities[c.latestMagicEventId ?? -1] ?? undefined,
    );
  }, shallowEqual);

  const allRunStates = useAppSessionCellsSelector({
    selector: airlockSessionCellSelector,
    equalityFn: shallowEqual,
  });
  const childCellRunStates = allRunStates.filter(notEmpty);
  const isPaused =
    parent?.status === MagicEventStatus.CANCELLED ||
    childCellRunStates.some((state) => state === CellExecutionState.ERRORED);

  const airlockRequestMode = parent?.requestMode ?? undefined;
  const pausedOnDeflection =
    parent?.status === MagicEventStatus.CANCELLED &&
    childCells.length === 0 &&
    (parent?.relatedProjectIds ?? []).length > 0;
  const pausedOnUnsupportedRequestMode =
    parent?.status === MagicEventStatus.CANCELLED &&
    childCells.length === 0 &&
    !pausedOnDeflection;

  const getCellContents = useCellContentsGetter();
  const noDataFromLatestSqlCell = useMemo(() => {
    if (
      chainTemplateType != null &&
      childCells.length > 0 &&
      childCells[childCells.length - 1].cellType === MagicCellType.SQL &&
      // We only want to return true here if there are more downstream cells in the chain
      // (i.e. not if this SQL cell is the final cell in the chain)
      childCells.length <
        CELL_TYPE_BY_CHAIN_TEMPLATE_TYPE[chainTemplateType].length
    ) {
      const cellContents = getCellContents(
        childCells[childCells.length - 1].id,
      );
      if (cellContents.__typename === "SqlCell")
        return (
          scopeMap[cellContents.resultVariable]?.dataFrameSchema?.rowCount === 0
        );
    }
    return false;
  }, [chainTemplateType, childCells, getCellContents, scopeMap]);

  const expectedCellCount = chainTemplateType
    ? CELL_TYPE_BY_CHAIN_TEMPLATE_TYPE[chainTemplateType].length
    : null;

  const loadingIndex = useMemo(() => {
    if (!isLoading) {
      return -1;
    }
    if (childCellRunStates.length === 0) {
      return 0;
    }

    const latestChildCellRunState =
      childCellRunStates[childCellRunStates.length - 1];

    if (
      latestChildCellRunState === CellExecutionState.STALE ||
      latestChildCellRunState === CellExecutionState.RUNNING ||
      latestChildCellRunState === CellExecutionState.QUEUED ||
      latestChildCellRunState === CellExecutionState.ERRORED
    ) {
      return childCells.length - 1;
    }

    const activeLoadingCell = childEvents?.find(
      (event) => event.status === MagicEventStatus.LOADING,
    )?.cellId;
    const activeLoadingIndex = childCells.findIndex(
      (cell) => cell.id === activeLoadingCell,
    );
    if (activeLoadingIndex == null || activeLoadingIndex === -1) {
      return childCells.length - 1;
    }
    return activeLoadingIndex;
  }, [isLoading, childCellRunStates, childEvents, childCells]);

  // When the chain fails, the error might be on the parent or the last child magic event
  let chainFailureReason: MagicEventFailureReason | undefined;
  if (
    parent?.failureReason != null ||
    parent?.status === MagicEventStatus.ERROR
  ) {
    chainFailureReason =
      parent.failureReason ||
      last(latestMagicEvents.map((e) => e?.failureReason).filter(notEmpty)) ||
      MagicEventFailureReason.GENERIC_ERROR;
  }

  return useMemo(
    () => ({
      airlockRequestMode,
      allRunStates,
      blockCellId,
      chainTemplateType,
      chainFailureReason,
      hasError: parent?.status === MagicEventStatus.ERROR,
      parentEventId: parent?.id,
      isFinished:
        parent?.status === MagicEventStatus.PENDING_REVIEW ||
        parent?.status === MagicEventStatus.REVIEWED,
      cellRunTimeout: parent?.cellRunResult === MagicCellRunResult.TIMEOUT,
      airlockOwner,
      lastPrompt,
      cells: sortedCells,
      childCellRunStates,
      latestMagicEvents,
      loadingIndex,
      isPaused,
      pausedOnUnsupportedRequestMode,
      pausedOnDeflection,
      isLoading,
      isErrored,
      noDataFromLatestSqlCell,
      dataConnectionId: parent?.dataConnectionId ?? undefined,
      parent,
      expectedCellCount,
    }),
    [
      airlockRequestMode,
      allRunStates,
      blockCellId,
      chainTemplateType,
      parent,
      airlockOwner,
      lastPrompt,
      sortedCells,
      chainFailureReason,
      childCellRunStates,
      latestMagicEvents,
      loadingIndex,
      isPaused,
      isLoading,
      isErrored,
      noDataFromLatestSqlCell,
      expectedCellCount,
      pausedOnUnsupportedRequestMode,
      pausedOnDeflection,
    ],
  );
};

export function useDiscard(cellId: CellId, eventId: MagicEventId) {
  const dispatch = useDispatch();
  const { nonAirlockCellCount } = useProjectCellCount();
  const getCells = useCellsGetter();
  const getMagicEvent = useMagicEventsGetter();
  const { dispatchAO: dispatchHexVersionAO } = useHexVersionAOContext();
  const currentUser = useCurrentUser();

  return useCallback(async () => {
    const magicEvent = getMagicEvent()[eventId];
    const promptV2 = magicEvent?.richTextPrompt ?? undefined;
    const magicEventCreatorId = magicEvent?.userId;

    const cells = getCells();
    const order = cells[cellId]?.order;

    // this will automatically delete all the cells in the airlock
    dispatchHexVersionAO([
      DISCARD_CHAIN.create({ id: eventId, parentCellId: cellId }),
    ]);
    // reopen lockbar, unless there are no cells in the project (in which case we'll
    // have the zerobar in the project zero state), only for the magic event's creator
    if (
      magicEventCreatorId != null &&
      currentUser?.id === magicEventCreatorId
    ) {
      if (nonAirlockCellCount > 0) {
        dispatch(openDraftAirlock({ promptV2, order }));
      } else {
        dispatch(setDraftAirlockPromptV2(promptV2));
      }
    }
  }, [
    getMagicEvent,
    eventId,
    getCells,
    cellId,
    dispatchHexVersionAO,
    currentUser?.id,
    nonAirlockCellCount,
    dispatch,
  ]);
}

export function useAcceptChain(cellId: CellId, eventId: MagicEventId) {
  const { dispatchAO: dispatchHexVersionAO } = useHexVersionAOContext();
  const { hexVersionId } = useProjectContext();
  const dispatch = useDispatch();
  const store = useStore();

  return useCallback(
    async (closeMagic?: boolean) => {
      if (closeMagic) {
        dispatch(
          hexVersionMPActions.setPromptBarOpen({
            hexVersionId,
            data: {
              promptBarOpen: false,
            },
          }),
        );
      }
      dispatchHexVersionAO(
        [
          ...getEjectBlockCellOps(cellId, hexVersionId, store.getState()),
          ...getAcceptMagicEventOps({
            magicEventId: eventId,
            state: store.getState(),
            hexVersionId,
          }),
        ],
        { skipUndoBuffer: true },
      );
    },
    [dispatchHexVersionAO, cellId, hexVersionId, store, eventId, dispatch],
  );
}

export function useAcceptAndContinue(userId?: UserId) {
  const startChain = useStartChain();
  const dispatch = useDispatch();
  const airlockCellGetter = useAirlockCellIdsGetter();
  const getCell = useCellGetter();
  const { dispatchAO: dispatchHexVersionAO } = useHexVersionAOContext();
  const store = useStore();
  const { hexVersionId } = useProjectContext();

  return useCallback(
    async ({ eventSource, order, prompt }) => {
      if (!userId) {
        throw new Error("User required to continue");
      }
      const airlockCellIds = airlockCellGetter();
      if (airlockCellIds.length > 0) {
        airlockCellIds.map((cellId) => {
          const cell = getCell(cellId);
          if (cell.blockCellId == null || cell.latestMagicEventId == null) {
            throw new Error("Incorrectly grabbed airlock cell id: " + cellId);
          }
          const magicEvent = hexVersionMPSelectors
            .getMagicEventSelectors(hexVersionId)
            .selectById(store.getState(), cell.latestMagicEventId);

          if (magicEvent?.userId === userId) {
            dispatchHexVersionAO(
              [
                ...getEjectBlockCellOps(cellId, hexVersionId, store.getState()),
                ...getAcceptMagicEventOps({
                  magicEventId: magicEvent.id,
                  state: store.getState(),
                  hexVersionId,
                }),
              ],
              { skipUndoBuffer: true },
            );
          }
        });
      }
      // we want a seamless transition between the two prompt bars
      dispatch(closeDraftAirlock({ disableCloseAnimation: true }));
      await startChain({ order, prompt, eventSource });
    },
    [
      airlockCellGetter,
      dispatch,
      dispatchHexVersionAO,
      getCell,
      hexVersionId,
      startChain,
      store,
      userId,
    ],
  );
}

gql`
  mutation cancelMagic($id: MagicEventId!) {
    cancelMagicRequest(id: $id)
  }
`;

export function usePauseChain(
  cellId: CellId,
  magicEventId: MagicEventId,
  cells: CellMP[],
  loadingIndex: number,
) {
  const discard = useDiscard(cellId, magicEventId);
  const { dispatchAO: dispatchAppSessionAO } = useAppSessionAOContext();
  const { dispatchAO: dispatchHexVersionAO } = useHexVersionAOContext();
  const [cancelMutation] = useCancelMagicMutation();

  return useCallback(async () => {
    // stop the airlock from executing
    void cancelMutation({ variables: { id: magicEventId } });
    dispatchAppSessionAO(INTERRUPT_APP_SESSION.create());

    if (cells.length === 0) {
      return discard();
    }

    const pendingCellsToDelete = cells
      .slice(loadingIndex)
      .filter((cell) => MagicNonStreamingCellType.guard(cell.cellType));

    if (pendingCellsToDelete.length > 0) {
      dispatchHexVersionAO(
        pendingCellsToDelete.map((cell) =>
          DELETE_CELL.create({ cellId: cell.id }),
        ),
        { skipUndoBuffer: true },
      );
      if (pendingCellsToDelete.length === cells.length) {
        return discard();
      }
    }
  }, [
    cancelMutation,
    magicEventId,
    dispatchAppSessionAO,
    cells,
    loadingIndex,
    discard,
    dispatchHexVersionAO,
  ]);
}

gql`
  mutation resumeChain(
    $id: MagicEventId!
    $startingCellId: CellId
    $startingCellContents: String
  ) {
    resumeMagicChain(
      parentEventId: $id
      startingCellId: $startingCellId
      startingCellContents: $startingCellContents
    ) {
      magicEvent {
        id
      }
    }
  }
`;

export function useResumeChain(eventId: MagicEventId) {
  const [resumeChain] = useResumeChainMutation();
  const getMagicEvent = useMagicEventsGetter();
  const getSelectedCellIds = useGetSelectedCellIds();
  const { selectCells } = useSelectCell();

  return useCallback(
    async (startingCellId?: CellId, startingCellContents?: string) => {
      // If the airlock has someone become unselected in the project. Select it on resume.
      // Without this, the prompt bar wouldn't be visible, making it much less clear magic is working.
      const magicEvent = getMagicEvent()[eventId];
      const selectedCellIds = getSelectedCellIds();
      if (magicEvent?.cellId && !selectedCellIds.has(magicEvent.cellId)) {
        selectCells([magicEvent.cellId]);
      }

      await resumeChain({
        variables: {
          id: eventId,
          startingCellId: startingCellId ?? null,
          startingCellContents: startingCellContents ?? null,
        },
      });
    },
    [getMagicEvent, eventId, getSelectedCellIds, resumeChain, selectCells],
  );
}

gql`
  mutation generateChain(
    $prompt: String!
    $hexVersionId: HexVersionId!
    $cellOrder: String!
    $mentionedTableIds: [DataSourceTableId!]!
    $richTextPrompt: RichTextDocument
    $mentionedDataframes: [MentionedDataframeName!]!
    $dataConnectionId: DataConnectionId
    $cellId: CellId!
    $blockCellId: BlockCellId!
    $parentEventId: MagicEventId!
    $eventSource: MagicEventSource
  ) {
    magicGenerateCells(
      prompt: $prompt
      hexVersionId: $hexVersionId
      cellOrder: $cellOrder
      chain: null
      cellType: null
      mentionedTableIds: $mentionedTableIds
      mentionedDataframes: $mentionedDataframes
      richTextPrompt: $richTextPrompt
      dataConnectionId: $dataConnectionId
      cellId: $cellId
      blockCellId: $blockCellId
      parentEventId: $parentEventId
      eventSource: $eventSource
    ) {
      magicEvent {
        id
      }
    }
  }
`;

export function useStartChain() {
  const [generateChain] = useGenerateChainMutation();
  const { hexVersionId } = useProjectContext();
  const dispatch = useDispatch();
  const { dispatchAO } = useHexVersionAOContext();
  const store = useStore();
  const { selectCells } = useSelectCell();
  const getCell = useCellGetter({ safe: true });

  return useCallback(
    async ({
      airlockCellId,
      eventSource,
      order,
      prompt,
    }: {
      prompt?: RichTextDocument;
      airlockCellId?: CellId;
      order: string;
      eventSource?: MagicEventSource;
    }) => {
      const args = prompt && getSubmitArgsFromRichText(prompt);
      if (!args) return;
      let dataConnectionId =
        args.mentionedDataConnectionId ??
        store.getState().logicView.lastUsedDataConnection ??
        null;

      if (args.dataframeSql) dataConnectionId = null;
      dispatch(setLastUsedDataConnection(dataConnectionId ?? undefined));

      dispatch(setDraftAirlockPromptV2(undefined));
      dispatch(setDraftAirlockOrder(order));

      const parentEventId = uuid() as MagicEventId;
      let blockCellId;
      let parentCellId;

      // We can start a new MA chain while reusing an existing blockCell to avoid remounting
      if (airlockCellId) {
        parentCellId = airlockCellId;
        const cell = getCell(airlockCellId);
        if (!cell?.blockCellId) throw new Error("Missing cell to reuse");
        blockCellId = cell.blockCellId;
        order = cell.order;
      } else {
        parentCellId = uuid() as CellId;
        blockCellId = uuid() as BlockCellId;

        await dispatchAO(
          CREATE_CELL.create({
            cellId: parentCellId,
            blockCellId: blockCellId,
            staticCellId: uuid() as StaticCellId,
            insertAt: order,
            insertAfter: undefined,
            insertBefore: undefined,
            latestMagicEventId: parentEventId,
            contents: createBlockCellPayload({
              id: blockCellId,
              blockConfig: {
                type: "AIRLOCK",
              },
            }),
            storyElement: createStoryElementPayload({
              id: uuid() as StoryElementId,
            }),
          }),
        )[1];
      }

      selectCells([parentCellId]);
      await generateChain({
        variables: {
          prompt: args.prompt,
          hexVersionId,
          cellOrder: order,
          mentionedTableIds: args.mentionedTableIds,
          mentionedDataframes: args.mentionedDataframes,
          richTextPrompt: prompt,
          dataConnectionId,
          cellId: parentCellId,
          blockCellId: blockCellId,
          parentEventId,
          eventSource: eventSource ?? null,
        },
      });
    },
    [
      store,
      dispatch,
      generateChain,
      hexVersionId,
      getCell,
      dispatchAO,
      selectCells,
    ],
  );
}

// Applies edits to cells. Chart and code cells use different endpoints
// to edit, and this fn abstracts away that difference.
export function useSingleCellMagicEdit(
  cellId?: CellId,
  cell?: CellMP,
  lastMagicEvent?: MagicEventFragment,
) {
  const editSyncCallback = useEditCellSync();
  const { acceptSmartEdit, cancelEdit, createSmartEdit, rejectSmartEdit } =
    useCreateSmartEdit({
      cellId,
      magicEventId: lastMagicEvent?.id,
    });

  const appSessionCellIdMap = useAppSessionCellIdMap();

  const { outputs } = useCellData({
    appSessionCellId: cellId ? appSessionCellIdMap[cellId] : undefined,
    filterErrors: false,
  });
  const traceback = getTraceback(outputs)?.traceback;

  const { hexVersionId } = useProjectContext();
  const magicStateLoading = useSelector((state) => {
    if (!cellId) return false;
    const map = state.hexVersionMP[hexVersionId]?.cellIdToMagicState;
    const mState = map && map[cellId];
    return mState ? mState.smartEditLoading : false;
  });

  // If the latest magic event exists we ALWAYS rely on that magic event for
  // state, and then if one doesn't exist, we use the redux state.
  // Practically this should only briefly happen when we kick off a request
  const smartEditLoading = lastMagicEvent
    ? lastMagicEvent.status === MagicEventStatus.LOADING
    : magicStateLoading;

  const magicEdit = useCallback(
    ({
      fix,
      generate = false,
      prompt,
    }: {
      prompt?: RichTextDocument;
      fix?: boolean;
      generate?: boolean;
    }) => {
      if (!cell) return { isSync: false };
      const args = prompt && getSubmitArgsFromRichText(prompt);
      const hasPrompt = args && !!args.prompt.trim();
      const useSync = SynchronousCellEdit.guard(cell?.cellType);
      // chart edits
      if (useSync) {
        // we don't yet have true automatic chart fixing, but just passing in the prompt "fix"
        // seems to work most of the time, so lets do that for now!
        void editSyncCallback({
          cellId: cell.id,
          prompt: args?.prompt ?? (fix ? "fix" : ""),
          mentionedTableIds: args?.mentionedTableIds ?? [],
          mentionedDataframes: args?.mentionedDataframes ?? [],
          richTextPrompt: prompt,
          smartEditType: MagicCellEditWithInput.CUSTOM,
          latestMagicEvent: lastMagicEvent,
        });
      }

      if (hasPrompt && !useSync) {
        createSmartEdit({
          kind: generate ? SmartEditType.INSERT : SmartEditType.CUSTOM,
          customInstruction: args.prompt,
          mentionedTableIds: args.mentionedTableIds,
          mentionedDataframes: args.mentionedDataframes,
          traceback,
          richTextPrompt: prompt,
        });
      }

      if (fix && !useSync) {
        createSmartEdit({
          kind: SmartEditType.FIX_ERRORS,
          setLoaded: true,
          traceback,
        });
      }

      return { isSync: useSync };
    },
    [cell, createSmartEdit, editSyncCallback, lastMagicEvent, traceback],
  );

  return {
    acceptSmartEdit,
    magicEdit,
    smartEditLoading,
    rejectSmartEdit,
    cancelEdit,
  };
}

export const SynchronousCellEdit = Union(Literal(CellType.CHART));
export type SynchronousCellEditTypes = Static<typeof SynchronousCellEdit>;

gql`
  mutation EditCellSync(
    $magicEventId: MagicEventId!
    $cellId: CellId!
    $kind: MagicCellEditWithInput!
    $customInstruction: String!
    $chartType: ChartType
    $sourceEventId: MagicEventId
    $mentionedTableIds: [DataSourceTableId!]!
    $richTextPrompt: RichTextDocument
    $mentionedDataframes: [MentionedDataframeName!]!
  ) {
    editCellSync(
      magicEventId: $magicEventId
      cellId: $cellId
      kind: $kind
      customInstruction: $customInstruction
      chartType: $chartType
      sourceEventId: $sourceEventId
      mentionedTableIds: $mentionedTableIds
      richTextPrompt: $richTextPrompt
      mentionedDataframes: $mentionedDataframes
    ) {
      error
      errorType
      user {
        id
        overMagicUsageLimit
      }
    }
  }
`;

export function useEditCellSync() {
  const [editCellSync] = useEditCellSyncMutation();
  const dispatch = useDispatch();
  const { hexVersionId } = useProjectContext();

  return useCallback(
    async ({
      cellId,
      chartType,
      latestMagicEvent,
      mentionedDataframes,
      mentionedTableIds,
      prompt,
      richTextPrompt,
      smartEditType,
    }: {
      cellId: CellId;
      prompt: string;
      smartEditType?: MagicKeyword | undefined;
      latestMagicEvent?: MagicEventFragment | null;
      chartType?: ChartTypeSelectorOption;
      mentionedTableIds?: DataSourceTableId[];
      richTextPrompt?: RichTextDocument;
      mentionedDataframes?: MentionedDataframeName[];
    }) => {
      dispatch(
        hexVersionMPActions.setSmartEditLoading({
          hexVersionId,
          cellId,
          data: true,
        }),
      );
      const stillThinkingTimeoutId = setTimeout(() => {
        dispatch(
          hexVersionMPActions.setStillThinking({
            hexVersionId,
            cellId,
            data: true,
          }),
        );
      }, STILL_THINKING_THRESHOLD);
      const magicEventIdForEdit = uuid() as MagicEventId;
      dispatch(
        hexVersionMPActions.setMagicEventId({
          hexVersionId,
          cellId,
          data: magicEventIdForEdit,
        }),
      );

      try {
        const response = await editCellSync({
          variables: {
            magicEventId: magicEventIdForEdit,
            cellId,
            customInstruction: prompt,
            kind: smartEditType as MagicCellEditWithInput,
            chartType: chartType ?? null,
            sourceEventId: latestMagicEvent?.id ?? null,
            mentionedTableIds: mentionedTableIds ?? [],
            richTextPrompt: richTextPrompt ?? null,
            mentionedDataframes: mentionedDataframes ?? [],
          },
        });
        if (response.data?.editCellSync?.error) {
          clearTimeout(stillThinkingTimeoutId);
          dispatch(
            hexVersionMPActions.setStillThinking({
              hexVersionId,
              cellId,
              data: false,
            }),
          );
          dispatch(
            hexVersionMPActions.setSmartEditErrored({
              hexVersionId,
              cellId,
            }),
          );
        }
      } catch (e) {
        console.error(e);
        clearTimeout(stillThinkingTimeoutId);
        dispatch(
          hexVersionMPActions.setStillThinking({
            hexVersionId,
            cellId,
            data: false,
          }),
        );
        dispatch(
          hexVersionMPActions.setSmartEditErrored({
            hexVersionId,
            cellId,
          }),
        );
      }
      dispatch(
        hexVersionMPActions.setStillThinking({
          hexVersionId,
          cellId,
          data: false,
        }),
      );
      dispatch(
        hexVersionMPActions.setSmartEditLoading({
          hexVersionId,
          cellId,
          data: false,
        }),
      );
      clearTimeout(stillThinkingTimeoutId);
    },
    [dispatch, hexVersionId, editCellSync],
  );
}

/*
 * This tells us if the output from a given cell was the result of running a pending magic edit or not
 **/
export function useOutputIsFromPendingMagicEvent(cellId?: CellId): boolean {
  const appSessionCellMap = useAppSessionCellIdMap();
  const appSessionCellId = cellId ? appSessionCellMap[cellId] : undefined;
  const { outputs, state } = useCellData({ appSessionCellId });

  const hasMagicOutputs = !!outputs.find((o) =>
    o.outputFragment.requestId?.startsWith("magic:"),
  );

  return hasMagicOutputs && state === CellExecutionState.IDLE;
}
